import { Container } from "../Container/Container";
import { TelLink } from "../Links.jsx";
import { FooterStyled } from "./Footer.styled";

export const Footer = () => {
  return (
    <FooterStyled>
      <Container>
        <p>© Infodate</p>
        <TelLink />
      </Container>
    </FooterStyled>
  );
};
