import { useDispatch } from "react-redux";
import { LinkStyled } from "./Logo.styled.js";
import { setIsOpenBurger } from "../../redux/app/slice.js";
import { svg } from "../../img/index.js";

export const Logo = ({ className }) => {
  const dispatch = useDispatch();

  const closeBurger = () => {
    dispatch(setIsOpenBurger(false));
  };
  return (
    <LinkStyled to={"/"} onClick={closeBurger} className={className}>
      <svg width={50} height={50}>
        <use href={svg + "#icon-infodate"} />
      </svg>
      <p>Infodate</p>
    </LinkStyled>
  );
};
