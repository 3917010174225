import styled from "styled-components";

export const LayoutStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: "main" "footer";
  position: relative;
  transition: grid-template-columns var(--transition),
    grid-template-areas var(--transition);

  min-height: 100vh;

  &.burger-open {
    overflow: hidden;
  }
`;

export const Main = styled.main`
  width: 100%;
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
