import { useDispatch } from "react-redux";
import { EmailLink, TelLink } from "../Links.jsx";
import {
  CloseButton,
  ContactDetails,
  Container,
  Form,
  InfoText,
  Input,
  LeftSide,
  RightSide,
  SubmitButton,
  TextArea,
  Title,
} from "./Contact.styled.js";
import { useCallback, useEffect, useState } from "react";
import { setIsOpenContact } from "../../redux/app/slice.js";
import { useLanguage } from "../../hooks/useLanguage.js";
import toast from "react-hot-toast";
import telegramApi from "../../api/telegram.js";
import * as Yup from "yup";

const schema = Yup.object().shape({
  name: Yup.string().trim().required("required"),
  email: Yup.string().email("Invalid email format").required("required"),
  subject: Yup.string().trim().required("required"),
  message: Yup.string().trim().required("required"),
});

export const Contact = ({ className }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [isValid, setValid] = useState(false);
  const {
    tooltip: { loading, sent },
  } = useLanguage();

  useEffect(() => {
    schema
      .validate(values)
      .then(() => setValid(true))
      .catch(() => setValid(false));
  }, [values]);

  const setFieldValue = useCallback((field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleClose = useCallback(() => {
    dispatch(setIsOpenContact(false));
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isValid || isSubmitting) return;
      const toastId = toast.loading(loading + "...");
      setSubmitting(true);
      try {
        await telegramApi.send(values);
        toast.success(sent, {
          id: toastId,
          duration: 6000,
        });
        setValues({});
        handleClose();
      } catch (error) {
        toast.error(error.response?.data?.message, { id: toastId });
      } finally {
        setSubmitting(false);
      }
    },
    [handleClose, isSubmitting, isValid, loading, sent, values]
  );

  const {
    contact_form: {
      send,
      message,
      desk,
      tel,
      email,
      your_name,
      your_email,
      subject,
      your_message,
      send_email,
    },
  } = useLanguage();

  return (
    <Container className={className}>
      <LeftSide>
        <Title>
          {send} <span>{message}</span>
        </Title>
        <InfoText>{desk}</InfoText>
        <ContactDetails>
          <span>
            {tel}: <TelLink className="tel-link" />
          </span>
          <span>
            {email}: <EmailLink className="email-link" />
          </span>
        </ContactDetails>
      </LeftSide>
      <RightSide>
        <CloseButton type="button" onClick={handleClose}>
          ×
        </CloseButton>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder={your_name}
            value={values.name || ""}
            onChange={({ target: { value } }) => setFieldValue("name", value)}
          />
          <Input
            type="email"
            placeholder={your_email}
            value={values.email || ""}
            onChange={({ target: { value } }) => setFieldValue("email", value)}
          />
          <Input
            type="text"
            placeholder={subject}
            value={values.subject || ""}
            onChange={({ target: { value } }) =>
              setFieldValue("subject", value)
            }
          />
          <TextArea
            rows="5"
            placeholder={your_message}
            value={values.message || ""}
            onChange={({ target: { value } }) =>
              setFieldValue("message", value)
            }
          />
          <SubmitButton type="submit" disabled={!isValid || isSubmitting}>
            {send_email}
          </SubmitButton>
        </Form>
      </RightSide>
    </Container>
  );
};
