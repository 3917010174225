import { Outlet } from "react-router-dom";
import { LayoutStyled, Main } from "./Layout.styled";
import Header from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { useEffect, useState } from "react";
import { useBurger } from "../../hooks/useBurger";
import { Burger } from "../Burger/Burger.jsx";
import { Sidebar } from "../Sidebar/Sidebar.jsx";
import { useSidebar } from "../../hooks/useSidebar.js";
import { useDispatch } from "react-redux";
import { setIsOpenSidebar } from "../../redux/app/slice.js";
import { useContact } from "../../hooks/useConatct.js";
import { Contact } from "../Contact/Contact.jsx";

const Layout = () => {
  const { isOpen: isOpenSidebar } = useSidebar();
  const dispatch = useDispatch();

  const { isOpen } = useBurger();
  const [isEnterDone, setIsEnterDone] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(isOpen);

  const { isOpen: isOpenContact } = useContact();
  const [isContactEnterDone, setIsContactEnterDone] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(isOpen);

  useEffect(() => {
    let timeoutId;
    if (isOpen) {
      clearTimeout(timeoutId);
      setIsBurgerOpen(true);
      timeoutId = setTimeout(() => setIsEnterDone(true), 10);
    } else {
      clearTimeout(timeoutId);
      setIsEnterDone(false);
      timeoutId = setTimeout(() => setIsBurgerOpen(false), 400);
    }
    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  useEffect(() => {
    let timeoutId;
    if (isOpenContact) {
      clearTimeout(timeoutId);
      setIsContactOpen(true);
      timeoutId = setTimeout(() => setIsContactEnterDone(true), 10);
    } else {
      clearTimeout(timeoutId);
      setIsContactEnterDone(false);
      timeoutId = setTimeout(() => setIsContactOpen(false), 400);
    }
    return () => clearTimeout(timeoutId);
  }, [isOpenContact]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        isOpenSidebar && dispatch(setIsOpenSidebar(false));
      } else {
        !isOpenSidebar && dispatch(setIsOpenSidebar(true));
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, isOpenSidebar]);

  return (
    <LayoutStyled>
      <Header />
      {isOpenSidebar && <Sidebar />}
      <Main>
        <Outlet />
      </Main>
      <Footer />
      {isBurgerOpen && <Burger className={isEnterDone ? "enter-done" : ""} />}
      {isContactOpen && (
        <Contact className={isContactEnterDone ? "enter-done" : ""} />
      )}
    </LayoutStyled>
  );
};

export default Layout;
