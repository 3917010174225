import { useLanguage } from "../../hooks/useLanguage.js";
import { handleScroll } from "../../other.js";

export const NavLinks = ({ className, handleClick = handleScroll }) => {
  const {
    nav: { links },
  } = useLanguage();

  return (
    <ul className={className}>
      {links.map(({ title, link }, index) => {
        return (
          <li key={index}>
            <button type="button" onClick={() => handleClick(link)}>
              {title}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
