import axios from "axios";

axios.defaults.baseURL = "https://peoplepulse-backend.onrender.com/api/v2";

export const send = async (body) => {
  const response = await axios.post(`/telegram`, body);
  return response;
};

const telegramApi = {
  send,
};

export default telegramApi;
