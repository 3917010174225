import { BurgerOverlay, BurgerStyled } from "./Burger.styled";
import { Container } from "../Container/Container";
import { useDispatch } from "react-redux";
import { setIsOpenBurger, setLaguage } from "../../redux/app/slice.js";
import { useCallback, useRef } from "react";
import { NavLinks } from "../NavLinks/NavLinks.jsx";
import { Logo } from "../Logo/Logo.jsx";
import { handleScroll } from "../../other.js";
import { useApp } from "../../hooks/useApp.js";

export const Burger = ({ className }) => {
  const nodeRef = useRef();
  const dispatch = useDispatch();
  const { language } = useApp();

  const toggleLanguage = useCallback(() => {
    const toLang = language === "ua" ? "en" : "ua";
    dispatch(setLaguage(toLang));
  }, [language, dispatch]);

  const hanldeClose = useCallback(() => {
    dispatch(setIsOpenBurger(false));
  }, [dispatch]);

  const handleOverlayClose = useCallback(
    (event) => {
      if (event.target === event.currentTarget) {
        hanldeClose();
      }
    },
    [hanldeClose]
  );

  const handleClick = useCallback(
    (link) => {
      handleScroll(link);
      dispatch(setIsOpenBurger(false));
    },
    [dispatch]
  );

  return (
    <BurgerOverlay className={className} onClick={handleOverlayClose}>
      <BurgerStyled
        ref={nodeRef}
        className={className}
        onMouseLeave={hanldeClose}
      >
        <Container>
          <Logo className="burger-logo" />
          <button type="button" onClick={toggleLanguage}>
            {language}
          </button>
          <NavLinks className="burger-nav-links" handleClick={handleClick} />
        </Container>
      </BurgerStyled>
    </BurgerOverlay>
  );
};
