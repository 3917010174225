import styled from "styled-components";

export const SidebarStyled = styled.aside`
  width: 100px;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 100px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  z-index: 8;

  & .email-link {
    transition: all var(--transition);

    &:hover {
      color: var(--accent);
    }
  }
`;

export const LanguageButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  text-transform: uppercase;
  color: var(--color);
  transition: all var(--transition);
  font-weight: 500;

  &:is(:hover) {
    color: var(--accent);
  }
`;

export const ScrollButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--color);
  transition: all var(--transition);
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & span {
    transition: all var(--transition);
  }

  &:is(:hover) {
    color: var(--accent);

    & span {
      background-color: var(--accent);
    }
  }
`;

export const ScrollSpan = styled.span`
  display: block;
  height: 50px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;

  & span {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color);
    position: absolute;
    bottom: -4px;
    left: -4px;
    transition: top 0.1s ease-in-out;
  }
`;
