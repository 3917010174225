import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
     :root {
          --transition: 300ms ease;
          --body-background: white;
          --color: #000;
          --second-color: #7A7A7A;
          --color-title: #1A3760;
          --accent: #FF5538 ;
          --second-accent: #FE7597;
          --blue-accent: #336AEA;
          --border-radius: 40px;
          --decor-color: #FFEAE6;
          --lqd-color-blue-200: rgba(0, 35, 82, 0.6);
          --lqd-color-blue-300: rgba(26, 55, 96, 0.831);
          
     }

     body {
          font-family: 'BeVietnamPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: var(--color);
          fill: var(--color);
          font-size: 1em;
          background-color: var(--body-background);

          &::-webkit-scrollbar {
               width: 8px;             /* width of the entire scrollbar */

          }

          &::-webkit-scrollbar-track {
               background: var(--body-background); 
  
          }

          &::-webkit-scrollbar-thumb {
               background-color: var(--color);
               border-radius: 10px;       /* roundness of the scroll thumb */
               border: 1px solid var(--body-background);  /* creates padding around scroll thumb */

               &:hover {
                    background-color: var(--accent);
               }
          }

     }    

     code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
     }

     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }

     svg {
          fill: inherit;
          stroke: inherit
     }
     

     .section-title {
          color: var(--color-title);
          font-size: 52px;
          text-shadow: 0 0 2px #fff;
          letter-spacing: -1px;

          @media screen and (width < 768px) {
               font-size: 6vw;
          }
     }

     .section-description {
          font-size: 20px;
          color: var(--second-color);
     }
`;
