import styled from "styled-components";

export const FooterStyled = styled.footer`
  width: 100%;
  grid-area: footer;
  transition: all var(--transition);
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  & .container {
    display: flex;
    gap: 60px;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    color: var(--second-color);

    & > a {
      transition: all var(--transition);

      &:hover {
        color: var(--accent);
      }
    }
  }
`;
