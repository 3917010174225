import styled from "styled-components";

export const ContainerStyled = styled.div`
  position: relative;
  margin: 0 auto;
  display: block;
  height: 100%;
  width: 100%;
  padding: 0 10px;

  @media screen and (width > 568px) {
    max-width: 540px;
    padding: 0;
  }

  @media screen and (width > 768px) {
    max-width: 720px;
  }

  @media screen and (width > 992px) {
    max-width: 960px;
  }

  @media screen and (width > 1280px) {
    max-width: 1140px;
  }

  @media screen and (width > 1400px) {
    max-width: 1170px;
  }
`;
