import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--body-background);
  transition: all var(--transition);
  opacity: 0;
  z-index: 11;
  transform: translateY(-100%);

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: #fff;
  min-height: 100vh;
  gap: 80px;

  @media screen and (width < 1200px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }

  &.enter-done {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const LeftSide = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: end;
  padding: 20px 20px 20px 200px;

  @media screen and (width < 1200px) {
    align-self: flex-end;
    justify-self: center;
    padding: 20px;
  }
`;

export const RightSide = styled.div`
  padding: 20px;
  justify-self: start;

  @media screen and (width < 1200px) {
    align-self: flex-start;
    justify-self: center;
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 122px;
  margin-bottom: 40px;
  font-weight: 500;
  display: inline-block;

  @media screen and (width < 1200px) {
    font-size: 50px;
  }

  & > span {
    font-style: italic;
    font-family: "DM Serif Text", serif;
  }
`;

export const InfoText = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
  color: var(--second-color);
`;

export const ContactDetails = styled.div`
  color: var(--second-color);
  display: flex;
  flex-direction: column;
  gap: 10px;

  & a {
    transition: all var(--transition);

    &:hover {
      color: var(--accent);
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 500px;
  gap: 20px;

  @media screen and (width < 1200px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  padding: 20px 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  width: 100%;
`;

export const TextArea = styled.textarea`
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
`;

export const SubmitButton = styled.button`
  background-color: #f2663b;
  color: white;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #d55b30;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: gray;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 45px;
  cursor: pointer;
  transition: all var(--transition);

  &:hover {
    color: var(--accent);
  }
`;
