import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { useApp } from "../../hooks/useApp";
import {
  setIsOpenBurger,
  setIsOpenContact,
  setIsOpenHeader,
} from "../../redux/app/slice";
import {
  BurgerButton,
  ContactButton,
  ContactsContainer,
  HeaderStyled,
  LogoContainer,
  PhoneContainer,
} from "./Header.styled";
import clsx from "clsx";
import { useBurger } from "../../hooks/useBurger";
import { Logo } from "../Logo/Logo.jsx";
import { NavLinks } from "../NavLinks/NavLinks.jsx";
import { useLanguage } from "../../hooks/useLanguage.js";
import { TelLink } from "../Links.jsx";

const Header = () => {
  const dispatch = useDispatch();
  const [isScroll, setIsScroll] = useState(false);
  const { isOpen } = useBurger();
  const {
    header: { consultation, contact, start_project },
  } = useLanguage();

  const { isOpenHeader } = useApp();

  const openBurger = useCallback(() => {
    dispatch(setIsOpenBurger(!isOpen));
  }, [dispatch, isOpen]);

  const openContact = useCallback(() => {
    dispatch(setIsOpenContact(true));
  }, [dispatch]);

  const hoverBurger = useCallback(() => {
    const { innerWidth } = window;
    if (innerWidth < 1200) return;
    openBurger();
  }, [openBurger]);

  let lastScrollTop = useRef(0);

  const handleScroll = useCallback(() => {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    let tempScroll = scrollTop - lastScrollTop.current;

    let tempUp = -30;
    let tempDown = 10;

    if (tempScroll < 0) {
      // scroll up
      if (!isOpenHeader && tempScroll < tempUp) {
        dispatch(setIsOpenHeader(true));
      }

      if (scrollTop <= 10 && isScroll) {
        setIsScroll(false);
      }
    } else {
      //scroll down
      if (scrollTop >= 80 && !isScroll) {
        setIsScroll(true);
      }

      if (scrollTop >= 100 && isOpenHeader && tempScroll > tempDown) {
        dispatch(setIsOpenHeader(false));
      }
    }

    lastScrollTop.current = scrollTop;
  }, [dispatch, isOpenHeader, isScroll]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <HeaderStyled
      className={clsx(isScroll && "scroll", isOpen && "burger-open")}
    >
      <LogoContainer>
        <Logo className="header-logo" />
      </LogoContainer>
      <NavLinks className={"header-nav-links"} />
      <ContactsContainer>
        <PhoneContainer>
          <p>{consultation}</p>
          <TelLink />
        </PhoneContainer>
        <ContactButton type="button" onClick={openContact}>
          <>
            <p className="text-one">{contact}</p>{" "}
            <p className="text-two">{start_project}</p>
          </>
        </ContactButton>
      </ContactsContainer>
      <BurgerButton
        type="button"
        onClick={openBurger}
        onMouseEnter={hoverBurger}
        className={isOpen && "is-open"}
      >
        <span></span>
        <span></span>
        <span></span>
      </BurgerButton>
    </HeaderStyled>
  );
};

export default Header;

/* <BurgerButton
  type="button"
  onClick={openBurger}
  className={clsx(
    isOpen && "is-open",
    (!isBanner || isScroll || isOpen) && "black"
  )}
>
  <span></span>
  <span></span>
  <span></span>
</BurgerButton>; */
