import styled from "styled-components";

export const BurgerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all var(--transition);
  opacity: 0;
  z-index: 11;
  visibility: hidden;
  will-change: opacity, visibility;

  &.enter-done {
    opacity: 1;
    visibility: visible;
  }

  @media screen and (width < 1200px) {
    z-index: 9;
    top: 100px;

    & .burger-logo {
      display: none;
    }
  }
`;

export const BurgerStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  transform: translateY(-100%);
  transition: transform 0.85s, opacity 0.85s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  background-color: white;
  will-change: transform, opacity;

  &.enter-done {
    transform: translateY(0);
  }

  @media screen and (width >= 1200px) {
    width: 350px;
    transform: translateX(-100%);

    &.enter-done {
      transform: translateX(0);
    }
  }

  & .container {
    display: grid;
    grid-template-rows: 100px 1fr;
    gap: 40px;
    align-items: center;
    padding: 0 60px;

    & > button {
      @media screen and (width >= 1200px) {
        display: none;
      }

      border: none;
      background-color: transparent;
      font-size: 24px;
      text-transform: uppercase;
    }

    & > .burger-nav-links {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;

      @media screen and (width < 1200px) {
        align-self: start;
      }

      & button {
        border: none;
        background-color: transparent;
        font-size: 30px;
        font-weight: 400;
        transition: all var(--transition);

        @media screen and (width < 1200px) {
          font-size: 24px;
          font-weight: 500;
          color: var(--color-title);
        }

        &:hover {
          color: var(--accent);
        }
      }
    }
  }
`;
