export const TelLink = ({ className }) => {
  return (
    <a className={className} href="tel:+380954096212">
      +38 (095) 409-62-12
    </a>
  );
};

export const EmailLink = ({ className }) => {
  return (
    <a className={className} href="mailto:infodate@ukr.net">
      infodate@ukr.net
    </a>
  );
};
