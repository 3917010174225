import { useCallback, useEffect } from "react";
import { useApp } from "../../hooks/useApp.js";
import {
  LanguageButton,
  ScrollButton,
  ScrollSpan,
  SidebarStyled,
} from "./Sidebar.styled.js";
import { useDispatch } from "react-redux";
import { setLaguage } from "../../redux/app/slice.js";
import { EmailLink } from "../Links.jsx";

export const Sidebar = () => {
  const { language } = useApp();
  const dispatch = useDispatch();

  useEffect(() => {
    const scrollbar = document.querySelector("#scroll-progress-bar");
    window.onscroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = winScroll / height;
      const styleText = `translate(0px, -${Math.round(scrolled * 50)}px)`;
      scrollbar.style.transform = styleText;
    };
  }, []);

  const toggleLanguage = useCallback(() => {
    const toLang = language === "ua" ? "en" : "ua";
    dispatch(setLaguage(toLang));
  }, [language, dispatch]);

  const scrollTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SidebarStyled>
      <ScrollButton type="button" onClick={scrollTop}>
        <ScrollSpan>
          <span id="scroll-progress-bar" />
        </ScrollSpan>
        scroll
      </ScrollButton>
      <EmailLink className="email-link" />
      <LanguageButton type="button" onClick={toggleLanguage}>
        {language}
      </LanguageButton>
    </SidebarStyled>
  );
};
