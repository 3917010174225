import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderStyled = styled.header`
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  grid-area: header;
  transition: all var(--transition);
  color: var(--color);
  height: 100px;
  padding: 15px 25px;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  gap: 25px;
  grid-template-areas: "logo burger-button";
  background-color: transparent;

  @media screen and (width >= 1200px) {
    grid-template-columns: 50px 200px 1fr 400px;
    grid-template-areas: "burger-button logo navigation contacts";
  }

  &.scroll,
  &.burger-open {
    background-color: rgba(255, 255, 255, 0.96);
    box-shadow: -2px 3px 90px -20px rgba(0, 0, 0, 0.25);

    @media screen and (width < 1200px) {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  .container {
    min-height: 78px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px 30px;

    & .header-nav {
      font-size: 16px;

      @media screen and (width < 1280px) {
        display: none;
      }

      & .active {
        color: var(--accent);
        pointer-events: none;
        text-shadow: 0 0 2px rgba(255, 255, 255, 0.25);
      }
    }
  }

  &.not-home-page {
    color: var(--color);
  }

  .header-nav-links {
    @media screen and (width < 1200px) {
      display: none;
    }

    @media screen and (width >= 1200px) {
      display: flex;
      align-items: center;
      gap: 20px;
      grid-area: navigation;
      transition: all var(--transition);

      & li {
        transition: all var(--transition);

        & button {
          font-size: 15px;
          color: var(--lqd-color-blue-300);
          font-weight: 700;
          padding: 25px 0;
          border: none;
          background-color: transparent;
          outline: none;
        }
      }

      &:is(:hover, :focus) {
        & li:not(:hover, :focus) {
          opacity: 0.35;
        }
      }
    }
  }
`;

export const ContactsContainer = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  grid-area: contacts;
  justify-content: end;

  @media screen and (width < 1200px) {
    display: none;
  }
`;

export const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;

  & p {
    color: var(--lqd-color-blue-200);
    font-size: 12px;
  }

  & a {
    color: var(--accent);
    font-size: 15px;
    font-weight: 700;
    transition: color var(--transition);

    &:is(:hover, :focus) {
      color: var(--second-accent);
    }
  }
`;

export const ContactButton = styled.button`
  height: 50px;
  width: 120px;
  border-radius: 100px;
  color: var(--color-title);
  text-transform: uppercase;
  border: none;
  outline: none;
  background-color: var(--body-background);
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.08);
  letter-spacing: 0.3px;
  font-size: 13px;
  font-weight: 700;
  transition: all var(--transition);
  overflow: hidden;
  position: relative;

  & .text-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.85s cubic-bezier(0.15, 0.85, 0.31, 1);
  }

  & .text-two {
    position: absolute;
    top: 200%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.85s cubic-bezier(0.15, 0.85, 0.31, 1);
    color: var(--body-background);
  }

  &:is(:hover, :focus) {
    background-color: var(--accent);

    & .text-one {
      position: absolute;
      top: -200%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & .text-two {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const LinkButtonStyled = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  min-height: 38px;
  border: 2px solid white;
  border-radius: 40px;
  padding: 6px 16px;

  @media (hover: hover) {
    /* when hover is supported */
    &:hover {
      background-color: var(--accent);
      border-color: var(--accent);
    }
  }
`;

export const BurgerButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  grid-area: burger-button;
  justify-self: end;

  @media screen and (width >= 1200px) {
    align-items: end;
    cursor: default;
    width: 50px;
    height: 50px;
    padding: 12px;
  }

  & span {
    background: #000;
    border-radius: 10px;
    height: 2px;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    @media screen and (width >= 1200px) {
      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        width: 70%;
      }

      &:nth-of-type(3) {
        width: 100%;
      }
    }

    @media screen and (width < 1200px) {
      &:nth-of-type(1) {
        width: 50%;
      }

      &:nth-of-type(2) {
        width: 100%;
      }

      &:nth-of-type(3) {
        width: 75%;
      }
    }
  }

  @media screen and (width < 1200px) {
    &.is-open {
      & span {
        &:nth-of-type(1) {
          transform-origin: bottom;
          transform: rotatez(45deg) translate(4px, 1px);
        }

        &:nth-of-type(2) {
          transform-origin: top;
          transform: rotatez(-45deg);
        }

        &:nth-of-type(3) {
          transform-origin: bottom;
          width: 50%;
          transform: translate(9px, -5px) rotatez(45deg);
        }
      }
    }
  }

  /* @media screen and (width >= 1280px) {
    display: none;
  } */
`;

export const LogoContainer = styled.div`
  grid-area: logo;
  justify-self: center;

  @media screen and (width < 1200px) {
    justify-self: left;
  }
`;
