import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinkStyled = styled(Link)`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  & svg {
    fill: var(--color-title);
  }

  & p {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--color-title);
  }
`;
